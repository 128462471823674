<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <!-- v-show="disabled" -->
                <c-btn
                  v-show="tbmData.tbmId"
                  label="출력"
                  icon="print"
                  @btnClicked="printTbm"
                />
                <c-btn
                  v-show="editable && !tbmData.tbmId"
                  label="타 TBM 불러오기"
                  icon="save_alt"
                  @btnClicked="copyTbm"
                />
                <c-btn
                  v-show="editable && tbmData.tbmId && !disabled"
                  label="삭제"
                  :editable="editable"
                  icon="delete_forever"
                  @btnClicked="remove"
                />
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="tbmData"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveTbm"
                  @btnCallback="saveCallback"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="TBM번호"
                  name="tbmNo"
                  v-model="tbmData.tbmNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM명"
                  name="tbmName"
                  v-model="tbmData.tbmName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :afterIcon="
                    editable && !disabled
                      ? [
                          {
                            name: 'search',
                            click: true,
                            callbackName: 'searchWorkPermit',
                          },
                          {
                            name: 'close',
                            click: true,
                            callbackName: 'removeWorkPermit',
                          },
                        ]
                      : null
                  "
                  :editable="editable"
                  :disabled="true"
                  label="작업허가번호"
                  name="permitNo"
                  v-model="tbmData.permitNo"
                  @searchWorkPermit="searchWorkPermit"
                  @removeWorkPermit="removeWorkPermit"
                >
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                  <!-- required -->
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="업체"
                  name="vendorName"
                  v-model="tbmData.vendorName"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="작업일"
                  type="date"
                  name="tbmWorkDate"
                  v-model="tbmData.tbmWorkDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                  <!-- required -->
                <c-process
                  :editable="editable"
                  :disabled="disabled"
                  label="공정"
                  name="processCd"
                  v-model="tbmData.processCd"
                >
                </c-process>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <!-- required -->
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="작업"
                  name="sopName"
                  v-model="tbmData.sopName"
                >
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :editable="editable"
                  :disabled="disabled"
                  label="작업책임자"
                  userType="user"
                  name="tbmWorkResponsibleId"
                  v-model="tbmData.tbmWorkResponsibleId"
                >
                </c-field>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <!-- 관련공사 -->
                <c-construnction
                  :disabled="disabled"
                  :editable="editable"
                  name="sopConstructionId"
                  v-model="tbmData.sopConstructionId">
                </c-construnction>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="tbmData.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-checkbox
                  :disabled="disabled"
                  :editable="editable"
                  :isArray="false"
                  codeGroupCd="TBM_PRE_INSPECTION_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="사전점검"
                  name="preInspections"
                  v-model="tbmData.preInspections"
                >
                </c-checkbox>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="작업내용"
                  :rows="2"
                  name="tbmWorkContents"
                  v-model="tbmData.tbmWorkContents"
                >
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="특이사항"
                  :rows="2"
                  name="significant"
                  v-model="tbmData.significant"
                >
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-table
            ref="riskTable"
            title="위험요인 목록"
            :columns="riskGrid.columns"
            :gridHeight="riskGrid.height"
            :data="tbmData.tbmRiskHazardList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="위험요인을 추가하세요."
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="tbmRiskId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline> 
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="추가"
                  icon="add"
                  @btnClicked="addRisk"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    tbmData.tbmRiskHazardList.length > 0
                  "
                  :showLoading="false"
                  label="제외"
                  icon="remove"
                  @btnClicked="removeRisk"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-table
            ref="equipTable"
            title="장비현황 목록"
            :columns="equipGrid.columns"
            :gridHeight="equipGrid.height"
            :data="tbmData.tbmEquipList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="장비현황을 추가하세요."
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="tbmEquipId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline> 
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="추가"
                  icon="add"
                  @btnClicked="addEquip"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    tbmData.tbmEquipList.length > 0
                  "
                  :showLoading="false"
                  label="제외"
                  icon="remove"
                  @btnClicked="removeEquip"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-table
            ref="attendeeTable"
            title="참석자 및 건강상태 목록"
            :columns="attendeeGrid.columns"
            :gridHeight="attendeeGrid.height"
            :data="tbmData.tbmAttendeeList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="참석자가 없습니다."
            :hideBottom="true"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="tbmAttendeeId"
            
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="추가"
                  icon="add"
                  @btnClicked="addAttendee1"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    tbmData.tbmAttendeeList.length > 0
                  "
                  :showLoading="false"
                  label="제외"
                  icon="remove"
                  @btnClicked="removeAttendee"
                />
              </q-btn-group>
            </template>
            <!-- <template v-slot:customArea="{ props, col }">
              <template v-if="col.name ==='tbmAttendeeUserName'">
                <c-text-column
                  v-if="props.row['tbmDirectFlag'] === 'Y'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeUserName']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeUserName']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmAttendeeJobName'">
                <c-text-column
                  v-if="props.row['tbmDirectFlag'] === 'Y'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeJobName']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeJobName']}}
                </span>
              </template>
            </template> -->
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-upload 
            style="padding-top:20px"
            :attachInfo="attachInfo"
            :editable="editable&&!disabled"
            label="교육자료">
          </c-upload>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from "quasar";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
import printJs from 'print-js'
export default {
  name: "tbm-detail",
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          tbmId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'TBM_EDUCATION',
        taskKey: '',
        beforeTaskKey: '',
      },
      tbmData: {
        befTbmId: "",
        tbmId: "",
        tbmNo: "",
        plantCd: "",
        permitNo: "",
        mdmSopId: "",
        sopName: "",
        sopMapId: '',
        mapName: '',
        tbmWorkResponsibleId: "",
        tbmWorkResponsibleName: "",
        tbmName: "",
        tbmWorkDate: "",
        tbmWorkArea: "",
        tbmWorkContents: "",
        significant: "",
        tbmCompleteFlag: 'N',
        processCd: "",
        vendorCd: "",
        sopWorkPermitId: "",
        preInspections: "",
        tbmWorkPermitFlag: 'N',
        sopConstructionId: '',

        tbmAttendeeList: [],
        tbmRiskHazardList: [],
        tbmEquipList: [],

        deleteTbmAttendeeList: [],
        deleteTbmRiskHazardList: [],
        deleteTbmEquipList: [],
      },
      riskGrid: {
        columns: [
          {
            name: "tbmJobStep",
            field: "tbmJobStep",
            label: "작업단계",
            align: "left",
            style: "width:30%",
            type: "text",
            sortable: false,
          },
          {
            name: "tbmRiskHazard",
            field: "tbmRiskHazard",
            label: "위험요인",
            align: "left",
            style: "width:30%",
            type: "text",
            sortable: false,
          },
          {
            name: "tbmImprovementMeasures",
            field: "tbmImprovementMeasures",
            label: "안전대책",
            type: "text",
            style: "width:40%",
            align: "left",
            sortable: false,
          },
        ],
        height: "250px",
        data: [],
      },
      attendeeGrid: {
        columns: [
          {
            name: "belongName",
            field: "belongName",
            label: "소속",
            style: 'width:120px',
            // type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: "tbmAttendeeUserName",
            field: "tbmAttendeeUserName",
            label: "참석자",
            style: 'width:120px',
            // type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: "tbmAttendeeJobName",
            field: "tbmAttendeeJobName",
            label: "직책",
            style: 'width:120px',
            // type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: 'mentalFatigueFlag',
            field: 'mentalFatigueFlag',
            label: '정신적<br>피로',
            style: 'width:50px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'mentalPhychologicalFlag',
            field: 'mentalPhychologicalFlag',
            label: '심리적<br>피로',
            style: 'width:50px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'mentalPhysicalFlag',
            field: 'mentalPhysicalFlag',
            label: '육체적<br>피로',
            style: 'width:50px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'physicalDefectFlag',
            field: 'physicalDefectFlag',
            label: '신체적<br>결함',
            style: 'width:50px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'healthStatusCd',
            field: 'healthStatusCd',
            label: '건강상태',
            type: 'select',
            setHeader: true,
            style: 'width:90px',
            align: 'center',
            codeGroupCd: 'TBM_HEALTH_STATUS_CD',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            style: 'width:250px',
            align: 'left',
            sortable: false,
          },
        ],
        height: "300px",
        data: [],
      },
      equipGrid: {
        columns: [
          {
            name: "equipName",
            field: "equipName",
            label: "장비명",
            align: "left",
            style: "width:80%",
            type: "text",
            sortable: false,
          },
          {
            name: "equipAmount",
            field: "equipAmount",
            label: "수량",
            align: "right",
            style: "width:20%",
            type: "number",
            sortable: false,
          },
        ],
        height: "250px",
        data: [],
      },
      getUrl: "",
      saveUrl: '',
      completeUrl: '',
      deleteUrl: "",
      updateUrl: "",
      printUrl: "",
      getWorkPermitUrl: '',
      saveType: "POST",
      isSave: false,
      isComplete: false,
      isEdit: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        // isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: false,
      isApproval: false,
      serverName: '',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    disabled() {
      return this.tbmData.tbmCompleteFlag === "Y" || this.tbmData.approvalStatusCd === 'ASC0000001';
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.serverName = this.$store.getters.serverUrl;
      this.tbmData.vendorCd = this.$store.getters.user.userId
      this.tbmData.vendorName = this.$store.getters.user.userName
      
      this.getWorkPermitUrl = selectConfig.sop.swp.workPermit.get.url;
      this.getUrl = selectConfig.sai.tbm.get.url;
      this.saveUrl = transactionConfig.sai.tbm.insert.url;
      this.insertUrl = transactionConfig.sai.tbm.insert.url;
      this.updateUrl = transactionConfig.sai.tbm.update.url;
      this.deleteUrl = transactionConfig.sai.tbm.delete.url;
      this.completeUrl = transactionConfig.sai.tbm.complete.url;
      this.printUrl = selectConfig.sai.tbm.print.url;

      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.tbmId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.tbmId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tbmData = this.$_.clone(_result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.tbmId);
        },);
      } else {
        this.tbmData.tbmWorkDate = this.$comm.getToday();
      }
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '20px',
        targetStyles: ['*'],
      })
    },
    saveTbm() {
      if (this.tbmData.tbmId) {
        this.saveUrl = this.updateUrl;
        this.saveType = "PUT";
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = "POST";
      }
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "저장하시겠습니까??",
            // TODO : 필요시 추가하세요.
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.tbmData.regUserId = this.$store.getters.user.userId;
              this.tbmData.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.popupParam.tbmId = _result.data
      if (this.saveType === 'POST') {
        this.$set(this.attachInfo, 'isSubmit', uid())
      } 
      this.getDetail();

      this.$set(this.attachInfo, 'taskKey', _result.data)
    },
    addRisk() {
      this.tbmData.tbmRiskHazardList.splice(0, 0, {
        tbmId: this.tbmData.tbmId,
        tbmRiskId: uid(),
        tbmJobStep: "",
        tbmRiskHazard: "",
        tbmImprovementMeasures: "",
        regUserId: this.$store.getters.user.userId,
        remarks: "",
        editFlag: "C",
      });
    },
    removeRisk() {
      let selectData = this.$refs["riskTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tbmData.deleteTbmRiskHazardList) {
            this.tbmData.deleteTbmRiskHazardList = [];
          }
          if (
            this.$_.findIndex(this.tbmData.deleteTbmRiskHazardList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tbmData.deleteTbmRiskHazardList.push(item);
          }
          this.tbmData.tbmRiskHazardList = this.$_.reject(
            this.tbmData.tbmRiskHazardList,
            item
          );
        });
      }
    },
    addEquip() {
      this.tbmData.tbmEquipList.splice(0, 0, {
        tbmId: this.tbmData.tbmId,
        tbmEquipId: uid(),
        equipName: "",
        equipAmount: 0,
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeEquip() {
      let selectData = this.$refs["equipTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tbmData.deleteTbmEquipList) {
            this.tbmData.deleteTbmEquipList = [];
          }
          if (
            this.$_.findIndex(this.tbmData.deleteTbmEquipList, {
              tbmEquipId: item.tbmEquipId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tbmData.deleteTbmEquipList.push(item);
          }
          this.tbmData.tbmEquipList = this.$_.reject(
            this.tbmData.tbmEquipList,
            item
          );
        });
      }
    },
    // TBM 참석자 선택
    addAttendee1() {
      this.popupOptions.title = "TBM 참석자 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.tbmData.plantCd,
        vendorCd: this.tbmData.vendorCd,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/vendor/vendorUserPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tbmData.tbmAttendeeList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.tbmData.tbmAttendeeList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeUserId: "",
              belongName: '(업체) ' + _item.vendorName,  // 소속명
              tbmAttendeeUserName: _item.personName,
              tbmAttendeeJobName: _item.jobName,
              healthStatusCd: "THS0000001",
              mentalFatigueFlag: "N",
              mentalPhychologicalFlag: "N",
              mentalPhysicalFlag: "N",
              physicalDefectFlag: "N",
              tbmDirectFlag: 'Y',
              electronSignature: '',
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addAttendee2() {
      // 참석자 직접추가
      this.tbmData.tbmAttendeeList.push({
        tbmId: this.popupParam.tbmId,
        tbmAttendeeId: uid(),
        tbmAttendeeUserId: "",
        tbmAttendeeUserName: "",
        tbmAttendeeJobName: "",
        healthStatusCd: "THS0000001",
        mentalFatigueFlag: "N",
        mentalPhychologicalFlag: "N",
        mentalPhysicalFlag: "N",
        physicalDefectFlag: "N",
        electronSignature: '',
        tbmDirectFlag: 'Y',
        remark: "",
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeAttendee() {
      let selectData = this.$refs["attendeeTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tbmData.deleteTbmAttendeeList) {
            this.tbmData.deleteTbmAttendeeList = [];
          }
          if (
            this.$_.findIndex(this.tbmData.deleteTbmAttendeeList, {
              tbmAttendeeId: item.tbmAttendeeId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tbmData.deleteTbmAttendeeList.push(item);
          }
          this.tbmData.tbmAttendeeList = this.$_.reject(
            this.tbmData.tbmAttendeeList,
            item
          );
        });
      }
    },
    copyTbm() {
      this.popupOptions.title = "타 TBM 검색"; // SOP 검색
      this.popupOptions.param = {
        type: "single",
        vendorCd: this.tbmData.vendorCd
      };
      this.popupOptions.target = () => import(`${"./tbmPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyTbmPopup;
    },
    closeCopyTbmPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.tbmData.tbmRiskHazardList = [];
      if (data && data.length > 0) {
        if (data[0].tbmId) {
          this.$http.url = this.$format(this.getUrl, data[0].tbmId);
          this.$http.type = "GET";
          this.$http.request((_result) => {
            this.tbmData.plantCd = _result.data.plantCd;
            this.tbmData.permitNo = _result.data.permitNo;
            this.tbmData.mdmSopId = _result.data.mdmSopId;
            this.tbmData.sopName = _result.data.sopName;
            this.tbmData.sopMapId = _result.data.sopMapId;
            this.tbmData.tbmName = _result.data.tbmName;
            this.tbmData.tbmWorkResponsibleId =
              _result.data.tbmWorkResponsibleId;
            this.tbmData.tbmWorkResponsibleName =
              _result.data.tbmWorkResponsibleName;
            this.tbmData.tbmWorkDate = this.$comm.getToday();
            this.tbmData.tbmWorkArea = _result.data.tbmWorkArea;
            this.tbmData.tbmWorkContents = _result.data.tbmWorkContents;
            this.tbmData.tbmCompleteFlag = "N";
            this.tbmData.processCd = _result.data.processCd;
            this.tbmData.vendorCd = _result.data.vendorCd;
            this.tbmData.sopWorkPermitId = _result.data.sopWorkPermitId;
            this.tbmData.preInspections = _result.data.preInspections;
            this.tbmData.tbmAttendeeList = _result.data.tbmAttendeeList;
            this.tbmData.tbmRiskHazardList = _result.data.tbmRiskHazardList;
            this.tbmData.tbmHazardEquipModelList = _result.data.tbmHazardEquipModelList;
            this.tbmData.tbmEquipList = _result.data.tbmEquipList;
            this.tbmData.befTbmId = data[0].tbmId


            this.$http.url = this.insertUrl;
            this.$http.type = 'POST';
            this.$http.param = this.tbmData;
            this.$http.request((_result) => {
              this.popupParam.tbmId = _result.data
              window.getApp.$emit("APP_REQUEST_SUCCESS");
              this.getDetail();
            },);
          });
        }
      }
    },
    printTbm() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.tbmData.tbmId);
      this.$http.type = "GET";
      this.$http.request(
        (_result) => {
          let fileOrgNm = this.tbmData.tbmNo + ".docx";
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        },
        () => {}
      );
    },
    // 작업허가서 선택
    searchWorkPermit() {
      if (!this.tbmData.plantCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '사업장을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
      }
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
        plantCd: this.tbmData.plantCd, 
        popupMode: 'Y',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.tbmData.tbmAttendeeList = [];
      if (data && data.length > 0) {
      this.$http.url = this.$format(this.getWorkPermitUrl, data[0].sopWorkPermitId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tbmData.sopWorkPermitId = _result.data.sopWorkPermitId
        this.tbmData.permitNo = _result.data.permitNo // 허가번호
        this.tbmData.tbmWorkDate = _result.data.permitDate // 작업일
        this.tbmData.processCd = _result.data.processCd // 공정
        this.tbmData.vendorCd = _result.data.vendorCd // 업체
        this.tbmData.mdmSopId = _result.data.mdmSopId // 작업
        this.tbmData.sopMapId = _result.data.sopMapId // 작업장소
        this.tbmData.tbmWorkResponsibleId = _result.data.maintenanceDeptCheckUserId // 책임자
        this.tbmData.tbmWorkResponsibleName = _result.data.maintenanceDeptCheckUserName // 책임자
        this.tbmData.sopName = _result.data.sopName // 작업
        this.tbmData.tbmName = _result.data.sopName // TBM명
        this.tbmData.tbmWorkContents = _result.data.workSummary // 작업내용
        this.tbmData.tbmWorkPermitFlag = "Y" // 작업허가서 유 
        
      },);
      }
    },
    removeWorkPermit() {
      window.getApp.$emit("CONFIRM", {
        title: "확인",
        message:
            "해당 작업허가서에 지정된 기본 정보들과 작업자가 삭제됩니다.\n\r진행하시겠습니까?",
        // TODO : 필요시 추가하세요.
        type: "info", // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.tbmData.sopWorkPermitId = '';
          this.tbmData.permitNo = '';
          this.tbmData.processCd = '';
          this.tbmData.mdmSopId = '';
          this.tbmData.sopName = '';
          this.tbmData.vendorCd = '';
          this.tbmData.vendorName = '';
          this.tbmData.tbmWorkDate = '';
          this.tbmData.tbmWorkResponsibleId = '';
          this.tbmData.tbmWorkResponsibleName = '';
          this.tbmData.mapName = '';
          this.tbmData.sopMapId = '';
          this.tbmData.tbmWorkContents = '';
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tbmData.tbmId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  },
};
</script>